import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "@app/services/Security/guard.service";
import { AppSettings } from "@app/helpers/AppSettings";
import { SearchInListComponent } from "@src/app/core/Search/search-in-list/search-in-list.component"; 
import { EmpleadosSettings } from "./config/empleados.settings";
import { EmpleadosComponent } from "./components/empleados.component";
import { EmpleadosSearch } from "./config/empleados.search";

const routes: Routes = [
  {
    path: "listado",
    component: SearchInListComponent,
    data: { title: "Empleados Listado" },
  },
  { path: "", component: EmpleadosComponent },
  { path: ":id", component: EmpleadosComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EmpleadosRoutingModule {
 
  //Routing principal
  public static RoutingEmpleados = {
    path: "nomina/empleados",
    loadChildren: () =>
      import("./empleados.module").then(
        (m) => m.EmpleadosModule
      ),
    canActivate: [AuthGuard],
    data: {
      title: `Empleados`,
      icon: "fa-duotone fa-solid fa-user-tie-hair",
      setting: EmpleadosSettings,
      search : EmpleadosSearch
    },
  };
}
