import { Injectable } from '@angular/core';
import { CaracteristicasModule, PermissionModule } from '@src/app/models/General/Permisos';
import { ReportEnvironment, ReportModel } from '@src/app/models/Report/report-setting';
import { AuthenticationService } from '@src/app/services/Security/authentication.service';
import { SettingsService } from '@src/app/services/Utilities/settings.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as CryptoJS from 'crypto-js';
import addMinutes from 'date-fns/addMinutes';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private userId: number;
  private token: string;

  constructor(
    private authenticationService: AuthenticationService,
    private settingsService: SettingsService
  ) {
    this.userId = this.authenticationService.getUserId();
    this.token = this.authenticationService.getToken();
  }

  /**
   * Opens a report in a new window
   * @param parameters Report parameters and configuration
   * @param idCaracteristica Characteristic ID for permissions
   * @param idRecurso Resource ID for permissions
   * @returns void
   */
  openReport(
    parameters: ReportModel,
    idCaracteristica: CaracteristicasModule,
    idRecurso: PermissionModule
  ): void {
    if (parameters) {
      const reportUrl = this.getReportUrl(parameters, idCaracteristica, idRecurso);
      setTimeout(() => {
        window.open(reportUrl, '_blank').focus();
      }, 100);
    }
  }

  /**
   * Generates the complete URL for the report
   */
  private getReportUrl(
    parameters: ReportModel,
    idCaracteristica: CaracteristicasModule,
    idRecurso: PermissionModule
  ): string {
    let param = '';
    let options = '';

    // Add environment parameter
    switch (this.settingsService.getBaseUrlReportViewer) {
      case ReportEnvironment.Production:
        parameters.parameters.push({ name: 'environment', value: ReportEnvironment.Production });
        break;
      case ReportEnvironment.Development:
        parameters.parameters.push({ name: 'environment', value: ReportEnvironment.Development });
        break;
      case ReportEnvironment.Testing:
        parameters.parameters.push({ name: 'environment', value: ReportEnvironment.Testing });
        break;
    }

    // Build parameters string
    parameters.parameters.forEach((element) => {
      param += `&${element.name}=${element.value}`;
    });

    // Build options string
    for (const property in parameters.options) {
      options += `&${property}=${parameters.options[property]}`;
    }

    // Create encrypted token
    const token = this.createToken(parameters.reportName, idCaracteristica, idRecurso);

    // Build complete URL
    return `${this.settingsService.getBaseUrlReportingServer}/?modulo=${parameters.module}&reporte=${parameters.reportName}${options}${param}&userId=${this.userId}&token=${token}`;
  }

  /**
   * Creates an encrypted token for report authentication
   */
  private createToken(
    reportName: string,
    idCaracteristica: CaracteristicasModule,
    idRecurso: PermissionModule
  ): string {
    let tokenOut = reportName;

    // Ensure token length is exactly 16 characters
    if (tokenOut.length < 16) {
      tokenOut = tokenOut.padEnd(16, '0');
    } else if (tokenOut.length > 16) {
      tokenOut = tokenOut.substring(0, 16);
    }

    const key = CryptoJS.enc.Utf8.parse(tokenOut);
    const iv = CryptoJS.enc.Utf8.parse(tokenOut);
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(this.token);

    const dataToSend = this.parseToken(decodedToken, idCaracteristica, idRecurso);
    const encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(JSON.stringify(dataToSend)),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );

    return encrypted
      .toString()
      .replaceAll('+', 'xMl3Jk')
      .replaceAll('/', 'Por21Ld')
      .replaceAll('=', 'Ml32');
  }

  /**
   * Parses JWT token and creates permission object
   */
  private parseToken(tokenData: any, idCaracteristica: CaracteristicasModule, idRecurso: PermissionModule) {
    const now = new Date();
    const dateini = addMinutes(now, 15);

    const permisosModulo = JSON.parse(tokenData.permissions).find(
      (s: { m: any }) => s.m == idRecurso
    ) as { m: any; c: any[] };

    const result2 = permisosModulo.c.filter(
      (s: { c: any }) => s.c == idCaracteristica
    ) as { c: any; l: number[] }[];

    let result = {} as { c: any; l: number[] };
    result.l = [];

    if (result2.length > 1) {
      result2.forEach(element => {
        result.c = element.c;
        const permissions = [0, 1, 2, 3, 4]; // Crear, Leer, Actualizar, Eliminar, Imprimir

        permissions.forEach(permission => {
          const hasPermission = element.l.includes(permission);
          if (hasPermission && !result.l.includes(permission)) {
            result.l.push(permission);
          }
        });
      });
    } else {
      result = result2[0];
    }

    return {
      institucionId: Number(tokenData.institution),
      userId: this.userId,
      expiration: dateini.getTime(),
      permiso: {
        Caracteristica: idCaracteristica,
        Modulo: idRecurso,
        Accion: result.l,
      },
    };
  }
}
